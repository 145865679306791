import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';

import { FormattedMessage } from '../../../util/reactIntl';

import { IconFile, IconCarouselArrow, Modal, Button } from '../../../components';
import AzureImageDisplay from '../../../components/AzureImageDisplay/AzureImageDisplay';

import css from './CertificatesPopup.module.css';
import OutsideClickHandler from '../../../components/OutsideClickHandler/OutsideClickHandler';

const CertificatesPopup = props => {
  const {
    onManageDisableScrolling,
    certificates
  } = props;

  if(!certificates?.length) return null

  const [modalOpen, setModalOpen] = useState(false)

  const items = !!certificates?.length && certificates.map(c => {
    return (
      <div className={css.certificatesListItem} key={c}>
        <AzureImageDisplay
          key={c}
          value={c}
        />
      </div>
    )
  })

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = items?.length;

  const prevButton = () => (
    <button
      onClick={() => slidePrev()}
      disabled={currentIndex === 0}
    >
      <IconCarouselArrow direction="left" disabled={currentIndex === 0} />
    </button>
  );

  const nextButton = () => (
    <button
      onClick={() => slideNext()}
      disabled={currentIndex === totalItems - 1}
    >
      <IconCarouselArrow direction="right" disabled={currentIndex === totalItems - 1} />
    </button>
  );

  const slidePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const slideNext = () => {
    setCurrentIndex((prev) => (prev < totalItems - 1 ? prev + 1 : prev));
  };

  const handleSlideChange = ({ item }) => {
    setCurrentIndex(item);
  };

  return (
    <div className={css.root}>

      <div
        className={css.certificatesBtn}
        onClick={() => setModalOpen(true)}
      >
        <IconFile />
        <FormattedMessage id="ListingPage.certificates" />
      </div>

      <Modal
        id="ListingPage.certificates"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={' '}
      >
        <div className={css.certificatesHolder}>

          <h2 className={css.certificatesTitle}>
            <FormattedMessage id="ListingPage.certificates" />
          </h2>

          <div className={css.certificatesCarousel}>
            <AliceCarousel
              items={items}
              infinite={false}
              activeIndex={currentIndex}
              onSlideChanged={handleSlideChange}
              renderPrevButton={prevButton}
              renderNextButton={nextButton}
              disableDotsControls
              disableSlideInfo={false}
            />
          </div>

          <Button type="button" onClick={() => setModalOpen(false)} className={css.certificatesPopupBtn} >
            <FormattedMessage id="ListingPage.backToProfile" />
          </Button>

        </div>
      </Modal>

    </div>
  );
};

export default CertificatesPopup;
